import React from "react";
import "./dashboard.css";
import Billpayment from "./billpaymentNav";
import { useNavigate } from "react-router-dom";
function BillpaymentBills() {
    const Navigate = useNavigate();
    const BillpayHandler = (e: any) => {
        if (e.currentTarget.id == "Insurance") {
            Navigate("/dashboard/bill-payments/insurance");
            return;
        }
        Navigate(
            `/dashboard/bill-payments/paybill-fetch/${e.currentTarget.id}`
        );
    };
    return (
        <div
            style={{
                background: "#F6F7FF",
                padding: "1rem 0rem",
            }}>
            <div className="payment-wrapper">
                <Billpayment active="billpay" />
                <div className="paymentType-wrapper">
                    {/* <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Broadband Postpaid">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/wifi.svg"
                            alt=""
                        />
                        <h2>
                            Broadband <br /> Postpaid{" "}
                        </h2>
                    </button> */}
                    {/* <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Cable Tv">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/cable-tv.svg"
                            alt=""
                        />
                        <h2>Cable TV </h2>
                    </button>
                    <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Clubs and Associations">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/network.svg"
                            alt=""
                        />
                        <h2>
                            Clubs and <br /> Associations
                        </h2>
                    </button>
                 
                    <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="DTH">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/satellite-dish.svg"
                            alt=""
                        />
                        <h2>DTH</h2>
                    </button> */}
                    {/* {localStorage.getItem("userid") != "1111111" && (
                        <button
                            className="payment-type"
                            onClick={BillpayHandler}
                            id="Credit Card">
                            <img
                                className="paymentType_icon"
                                src="assets/billpayment/icons/credit-card.svg"
                                alt=""
                            />
                            <h2>Credit Card</h2>
                        </button>
                    )} */}
                    <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Electricity">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/electrical-energy.svg"
                            alt=""
                        />
                        <h2>Electricity</h2>
                    </button>
                    {/* <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Fastag">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/toll.svg"
                            alt=""
                        />
                        <h2>Fastag</h2>
                    </button> */}
                    <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Gas">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/gas.svg"
                            alt=""
                        />
                        <h2>Gas</h2>
                    </button>
                    {/* <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Insurance">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/family.svg"
                            alt=""
                        />
                        <h2>Insurance</h2>
                    </button>
                    <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Hospital and pathology">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/hospital.svg"
                            alt=""
                        />
                        <h2>
                            Hospital and <br />
                            Pathology
                        </h2>
                    </button> */}
                    {/* <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Landline Postpaid">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/landline.svg"
                            alt=""
                        />
                        <h2>Landline Postpaid</h2>
                    </button> */}
                    {/* <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Loan Repayment">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/money-bag.svg"
                            alt=""
                        />
                        <h2>Loan Repayment</h2>
                    </button>
                    <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="LPG Gas">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/lpg.svg"
                            alt=""
                        />
                        <h2>LPG Gas</h2>
                    </button>
                    <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Subscription">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/Subscription.svg"
                            alt=""
                        />
                        <h2>Subscription</h2>
                    </button>
                  
                    <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Municipal Services">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/central-park.svg"
                            alt=""
                        />
                        <h2>Municipal Services</h2>
                    </button>
                    <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Municipal Taxes">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/taxes.svg"
                            alt=""
                        />
                        <h2>Municipal Taxes</h2>
                    </button> */}
                    {/* <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Mobile Postpaid">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/smartphone.svg"
                            alt=""
                        />
                        <h2>Mobile Postpaid</h2>
                    </button> */}
                    {/* <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Mobile Prepaid">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/phonePrepaid.svg"
                            alt=""
                        />
                        <h2>Mobile Prepaid</h2>
                    </button> */}
                    <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Water">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/water-drop.svg"
                            alt=""
                        />
                        <h2>Water</h2>
                    </button>
                    {/*   <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Education Fees">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/scholarship.svg"
                            alt=""
                        />
                        <h2>Education Fees</h2>
                    </button> */}
                    {/* <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Housing Society">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/social-housing.svg"
                            alt=""
                        />
                        <h2>Housing Society</h2>
                    </button> */}
                </div>
            </div>
        </div>
    );
}

export default BillpaymentBills;
