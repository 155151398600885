import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import BillpaymentBills from "./billpaymentBills";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function KycPending() {
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");
    // const Navigate=useNavigate()
    const [walletBlc, setWalletBlc] = useState<any>(0);

    const userId = localStorage.getItem("userid");

    /* useEffect(() => {
        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes"]); */
    // showSection == 'biller-list'
    const BillpayHandler = () => {
        navigate("/dashboard/pay-bills");
    };
    return (
        <>
            <div className="main-bg">
                <div>
                    <DashboardNav active={"rent-pay"}></DashboardNav>
                </div>
                <div className="paymentWrapper">
                    <div className="payment-header">
                        <h2> Rent Pay </h2>
                        <img
                            className="payment-header-icon"
                            src="assets/billpayment/billpayment_header.png"
                            alt=""
                        />
                    </div>
                </div>

                <div className="payment-wrapper">
                    <div className="paymentType-wrapper">
                        {/* <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Broadband Postpaid">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/wifi.svg"
                            alt=""
                        />
                        <h2>
                            Broadband <br /> Postpaid{" "}
                        </h2>
                    </button> */}
                        {/* <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Cable Tv">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/cable-tv.svg"
                            alt=""
                        />
                        <h2>Cable TV </h2>
                    </button>
                    <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="Clubs and Associations">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/network.svg"
                            alt=""
                        />
                        <h2>
                            Clubs and <br /> Associations
                        </h2>
                    </button>
                 
                    <button
                        className="payment-type"
                        onClick={BillpayHandler}
                        id="DTH">
                        <img
                            className="paymentType_icon"
                            src="assets/billpayment/icons/satellite-dish.svg"
                            alt=""
                        />
                        <h2>DTH</h2>
                    </button> */}
                        <button
                            className="payment-type"
                            onClick={BillpayHandler}
                            id="Credit Card">
                            <img
                                className="paymentType_icon"
                                src="assets/rent.png"
                                alt=""
                            />
                            <h2>House Rent</h2>
                        </button>
                        <button
                            className="payment-type"
                            onClick={BillpayHandler}
                            id="Electricity">
                            <img
                                className="paymentType_icon"
                                src="assets/property.png"
                                alt=""
                            />
                            <h2>Property Rent</h2>
                        </button>
                        <button
                            className="payment-type"
                            onClick={BillpayHandler}
                            id="Electricity">
                            <img
                                className="paymentType_icon"
                                src="assets/key.png"
                                alt=""
                            />
                            <h2>Property Token</h2>
                        </button>
                        <button
                            className="payment-type"
                            onClick={BillpayHandler}
                            id="Electricity">
                            <img
                                className="paymentType_icon"
                                src="assets/donation.png"
                                alt=""
                            />
                            <h2>Property Deposit</h2>
                        </button>
                    </div>
                </div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;
