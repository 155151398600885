import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import "./dashboard.css";
import WalletBlcHeader from "./walletBlcHeader";
import LoginServices from "../../services/LoginServices";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import BillpaymentNav from "./billpaymentNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaUserCircle } from "react-icons/fa";
import Razorpay from "./RazorBillPay";
import Confirm from "./ConfirmModal/Confirm";
import Spinner from "../spinner";
const override = css`
    display: block;
    margin: 0 auto;
    border-color: "#010080";
`;

function KycPending() {
    const [PayableAmount, setPayableAmount] = useState(0);
    const [minData, setMinData] = useState({ min: 0 });
    const [PaymentGateWay, setGateway] = useState<any>("");
    const [AllGateways, setAllGateways] = useState({
        isRazorPayPGAvailable: 1,
        isCashfreeEduAvailable: 0,
        isCashFreeAvailable: 0,
    });
    const [commissionPercentage, setCommissionPercentage] = useState(1.5);
    const [modal, setModal] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [ApiData_send, setApiData_send] = useState<any>();
    const { type } = useParams();
    const [billdetails, setbilldetails] = useState<any>();
    const [apiData, setApiData] = useState();
    const [billername, setbillername] = useState<any>(
        sessionStorage.getItem("billerName")
    );
    // const billername = ;
    const [userIpAddress, setUserIpAddress] = useState("");
    let bill = sessionStorage.getItem("billdetails");
    const [amount, setamount] = useState<any>();
    let [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [refreshP, setRefreshP] = useState<any>("");

    const [walletBlc, setWalletBlc] = useState<any>(0);

    const userId = localStorage.getItem("userid");
    // useEffect(() => {
    //     // Check if the page was reloaded (i.e., a refresh)
    //     if (window.performance.navigation.type === 1) {
    //         // Redirect to a different page after refresh
    //         window.location.replace("/dashboard/bill-payments"); // Change this URL to where you want to redirect
    //     }
    // }, []);
    useEffect(() => {
        let userDetails: any = localStorage.getItem("userdetails");
        if (userDetails) {
            userDetails = JSON.parse(userDetails);
            setCommissionPercentage(userDetails?.BBPSChargesPercentage);
            setPayableAmount(
                (Number(sessionStorage.getItem("amount")) / 100) *
                    (userDetails?.BBPSChargesPercentage / 100) +
                    Number(sessionStorage.getItem("amount")) / 100
            );
        } else {
            setPayableAmount(
                Math.ceil(
                    (Number(sessionStorage.getItem("amount")) / 100) *
                        (1.5 / 100) +
                        Number(sessionStorage.getItem("amount")) / 100
                )
            );
        }

        setamount(Number(sessionStorage.getItem("amount")) / 100);
        if (bill) {
            setbilldetails(JSON.parse(bill));
            console.log(JSON.parse(bill));
        }
    }, []);

    useEffect(() => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.getGatewayStatus(config)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                    setAllGateways({
                        isCashFreeAvailable:
                            response?.data?.isCashFreeAvailable,
                        isCashfreeEduAvailable:
                            response?.data?.isCashfreeEduAvailable,
                        isRazorPayPGAvailable:
                            response?.data?.isRazorPayPGAvailable,
                    });
                } else {
                    alert("something went wrong pleasetry again");
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }, []);
    useEffect(() => {
        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                console.log(response);
                setUserIpAddress(response.ip);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const amountChange = (e: any) => {
        setamount(e.target.value);
        setPayableAmount(
            Math.ceil(
                (Number(e.target.value) * (commissionPercentage / 100) +
                    Number(e.target.value)) *
                    100
            ) / 100
        );
    };
    console.log(amount);
    useEffect(() => {
        // if (bill) {
        //     setbilldetails(JSON.parse(billdetails));
        //     console.log(billdetails);
        // }

        if (userId) {
            let config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            LoginServices.getWalletBalanceByUserID(parseInt(userId), config)
                .then((response: any) => {
                    console.log(response);
                    if (response.data.Status == 1) {
                        setWalletBlc(response?.data?.walletBalance);
                    } else {
                        alert(response?.data?.Message);
                    }

                    console.log(response.data);
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, [refreshP == "yes", bill]);
    // showSection == 'biller-list'
    const payHandler = () => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        const billerAdhoc = localStorage.getItem("billerAdhoc");
        let id = {};
        if (billerAdhoc) {
            id = {
                RequestID: billdetails?.RequestID,
            };
        }
        let additionalInfo: any = [];
        if (billdetails?.BillDetails?.additionalInfo) {
            additionalInfo = Array.isArray(
                billdetails?.BillDetails?.additionalInfo?.info
            )
                ? [...billdetails?.BillDetails?.additionalInfo?.info]
                : [{ ...billdetails?.BillDetails?.additionalInfo?.info }];
        }

        let data: any = {
            billerId: sessionStorage.getItem("billerid"),
            Amount: Number(amount) * 100,
            charges: PayableAmount - Number(amount),
            billerAdhoc: localStorage.getItem("billerAdhoc"),
            ...id,
            mobileNumber: localStorage.getItem("mobilenumber"),
            Params: {
                additionalInfo: {
                    info: additionalInfo.length > 0 ? additionalInfo : 0,
                },
                inputParams: {
                    input: [...billdetails?.BillDetails?.inputParams.input],
                },
                billerResponse: { ...billdetails?.BillDetails?.billerResponse },

                paymentMethod: {
                    paymentMode: "NEFT",
                    quickPay: "N",
                    splitPay: "N",
                },
                amountInfo: {
                    amount: Number(amount) * 100,
                    currency: "356",
                    custConvFee: "0",
                },
                paymentInfo: {
                    info: [
                        {
                            infoName: "IFSC",
                            infoValue: "TESTCCAV0001",
                        },
                        {
                            infoName: "AccountNo",
                            infoValue: "PG",
                        },
                    ],
                },
            },
        };

        if (additionalInfo.length > 0) {
            data = {
                ...data,
                additionalInfo: { info: additionalInfo },
            };
        }

        console.log(data);

        setSpinner(true);

        LoginServices.paybill(data, config)
            .then((response: any) => {
                setSpinner(false);
                if (response.data?.Status == 1) {
                    setModal(false);
                    sessionStorage.setItem(
                        "success",
                        JSON.stringify(response?.data?.BillPayResponse)
                    );
                    navigate(
                        `/dashboard/bill-payments/paybill-success/${type}`
                    );
                } else if (response?.data.Status == 0) {
                    alert(response?.data?.Message);
                }
                console.log(response);
            })
            .catch((err: any) => {
                setSpinner(false);
                console.log(err);
                alert("some thing went wrong try after sometime");
            });
    };

    const Payhandle = () => {
        if (PaymentGateWay == "") {
            alert("select Payment Gateway");
            return;
        }
        let additionalInfo1 = Array.isArray(
            billdetails?.BillDetails?.additionalInfo?.info
        )
            ? [...billdetails?.BillDetails?.additionalInfo?.info]
            : [{ ...billdetails?.BillDetails?.additionalInfo?.info }];
        let data1 = additionalInfo1.filter(
            (item: any) => item.infoName == "Minimum Amount for Top-up"
        );
        if (data1 && data1.length > 0 && data1[0]?.infoValue > Number(amount)) {
            return alert("Min Payable Amount is " + data1[0].infoValue);
        }

        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        const billerAdhoc = localStorage.getItem("billerAdhoc");
        let id = {};
        if (billerAdhoc) {
            id = {
                RequestID: billdetails?.RequestID,
            };
        }
        let additionalInfo: any = [];
        if (billdetails?.BillDetails?.additionalInfo) {
            additionalInfo = Array.isArray(
                billdetails?.BillDetails?.additionalInfo?.info
            )
                ? [...billdetails?.BillDetails?.additionalInfo?.info]
                : [{ ...billdetails?.BillDetails?.additionalInfo?.info }];
        }

        let data: any = {
            billerId: sessionStorage.getItem("billerid"),
            Amount: Number(amount) * 100,
            charges:
                PaymentGateWay == 1
                    ? PayableAmount - Number(amount)
                    : PaymentGateWay == 2
                    ? (Number(amount) * 0.017).toFixed(2)
                    : (Number(amount) * 0.02).toFixed(2),
            billerAdhoc: localStorage.getItem("billerAdhoc"),
            ...id,
            mobileNumber: localStorage.getItem("mobilenumber"),
            Params: {
                additionalInfo: {
                    info: additionalInfo.length > 0 ? additionalInfo : 0,
                },
                inputParams: {
                    input: [...billdetails?.BillDetails?.inputParams.input],
                },
                billerResponse: { ...billdetails?.BillDetails?.billerResponse },

                paymentMethod: {
                    paymentMode: "NEFT",
                    quickPay: "N",
                    splitPay: "N",
                },
                amountInfo: {
                    amount: Number(amount) * 100,
                    currency: "356",
                    custConvFee: "0",
                },
                paymentInfo: {
                    info: [
                        {
                            infoName: "IFSC",
                            infoValue: "TESTCCAV0001",
                        },
                        {
                            infoName: "AccountNo",
                            infoValue: "PG",
                        },
                    ],
                },
            },
        };
        if (additionalInfo.length > 0) {
            data = {
                ...data,
                additionalInfo: { info: additionalInfo },
            };
        }
        localStorage.setItem("ApidATA_A", JSON.stringify(data));

        setModal(true);
    };
    return (
        <>
            {spinner && <Spinner />}
            {modal && (
                <Confirm
                    submitenabler={spinner}
                    commissionPercentage={commissionPercentage}
                    setSpinner={setSpinner}
                    payHandler={payHandler}
                    passBlc={amount}
                    apidatasend={ApiData_send}
                    commissionamount={PayableAmount}
                    show={modal}
                    PaymentGateWay={PaymentGateWay}
                    onHide={() => {
                        setModal(false);
                    }}
                />
            )}
            <div className="main-bg">
                <div>
                    <DashboardNav active={"bill-payments"}></DashboardNav>
                </div>
                <div style={{ backgroundColor: "#f6f7ff" }}>
                    <div className="paymentWrapper">
                        <div className="payment-header">
                            <div className="header-wrapper">
                                <h2>Pay Bills</h2>
                                <img
                                    style={{ alignSelf: "center" }}
                                    src="/assets/img/arrow.svg"
                                    alt=""
                                />
                                <h3>{type}</h3>
                            </div>
                            <img
                                className="payment-header-icon"
                                src="assets/billpayment/billpayment_header.png"
                                alt=""
                            />
                        </div>
                    </div>

                    <div className="amount-wrapper">
                        <div className="amount-data">
                            <label htmlFor="billername">Biller Name</label>
                            <input
                                type="text"
                                placeholder={billername}
                                disabled
                            />
                        </div>
                        {(Array.isArray(
                            billdetails?.BillDetails?.additionalInfo?.info
                        )
                            ? [
                                  ...billdetails?.BillDetails?.additionalInfo
                                      ?.info,
                              ]
                            : [billdetails?.BillDetails?.additionalInfo?.info]
                        ).map((item: any) => {
                            return (
                                <div className="amount-data">
                                    <label htmlFor={item?.infoName}>
                                        {item?.infoName}
                                    </label>
                                    <input
                                        type="text"
                                        placeholder={item?.infoValue}
                                        disabled
                                    />
                                </div>
                            );
                        })}
                        {billdetails?.BillDetails?.inputParams?.input?.map(
                            (item: any) => {
                                return (
                                    <div className="amount-data">
                                        <label htmlFor={item?.input?.paramName}>
                                            {item?.paramName}
                                        </label>
                                        <input
                                            type="text"
                                            placeholder={item?.paramValue}
                                            disabled
                                        />
                                    </div>
                                );
                            }
                        )}
                        <div className="amount-data">
                            <label htmlFor="due date">Due Date</label>
                            <input
                                type="text"
                                placeholder={
                                    billdetails?.BillDetails?.billerResponse
                                        ?.dueDate
                                }
                                disabled
                            />
                        </div>
                        <div className="amount-data">
                            <label htmlFor="billAmount">bill Amount</label>
                            <input
                                type="number"
                                value={amount}
                                onChange={amountChange}
                            />
                        </div>
                        <div
                            className={"drop-down amount-data"}
                            style={{ gridColumn: "1/-1" }}>
                            <label htmlFor="">Payment Gateway</label>
                            <select
                                name=""
                                id=""
                                value={PaymentGateWay}
                                onChange={(e) => setGateway(e.target.value)}
                                style={{ display: "block" }}>
                                <option value="">Select Payment Gateway</option>
                                <option
                                    value={2}
                                    disabled={
                                        AllGateways?.isCashFreeAvailable == 0
                                    }>
                                    Cashfree Utility{" "}
                                </option>
                                {localStorage.getItem("userid") !=
                                    "1111111" && (
                                    <option
                                        value={3}
                                        disabled={
                                            AllGateways?.isCashfreeEduAvailable ==
                                            0
                                        }>
                                        Cashfree Edu{" "}
                                    </option>
                                )}

                                <option
                                    value={1}
                                    disabled={
                                        AllGateways?.isRazorPayPGAvailable == 0
                                    }>
                                    Razor Pay{" "}
                                </option>
                            </select>
                        </div>
                        <button onClick={Payhandle}>Pay</button>
                    </div>
                </div>

                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </>
    );
}

export default KycPending;
