import React, { useState, useEffect, useRef } from "react";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DashboardNav from "./dashboardNav";
import CardClasses from "./Card.module.css";
import LoginServices from "../../services/LoginServices";
import { Modal, ModalBody } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { useForm, Controller, SubmitHandler, set } from "react-hook-form";
import Spinner from "../spinner";
import Confirm from "./ConfirmModal/Confirm";

type FormData = {
    amount: number;
};
function CardPayments() {
    const [userIpAddress, setUserIpAddress] = useState("");
    const [PayableAmount, setPayableAmount] = useState(0);
    const [commissionPercentage, setCommissionPercentage] = useState(1.5);
    const [spin, setSpin] = useState(false);
    const [Cards, setCards] = useState([]);
    const [AllCards, setAllCards] = useState([]);
    const navigate = useNavigate();
    const [load, setLoading] = useState(true);
    const [selectedata, SetSelectedData] = useState<any>({});
    const [Edit, setEdit] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successData, setSuccessData] = useState<any>();
    const [modal, setModal] = useState(false);
    const [PaymentGateWay, setGateway] = useState<any>("");
    const [AllGateways, setAllGateways] = useState({
        isRazorPayPGAvailable: 1,
        isCashfreeEduAvailable: 0,
        isCashFreeAvailable: 0,
    });
    const userId = localStorage.getItem("userid");
    const {
        register,
        handleSubmit,
        watch,
        control,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors },
    } = useForm<FormData>();
    const formData = watch();

    useEffect(() => {
        fetch("https://api.ipify.org/?format=json")
            .then((Response) => {
                return Response.json();
            })
            .then((response) => {
                console.log(response);
                setUserIpAddress(response.ip);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    useEffect(() => {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.getGatewayStatus(config)
            .then((response: any) => {
                console.log(response);
                if (response.data.Status == 1) {
                    setAllGateways({
                        isCashFreeAvailable:
                            response?.data?.isCashFreeAvailable,
                        isCashfreeEduAvailable:
                            response?.data?.isCashfreeEduAvailable,
                        isRazorPayPGAvailable:
                            response?.data?.isRazorPayPGAvailable,
                    });
                } else {
                    alert("something went wrong pleasetry again");
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }, []);
    const getCards = () => {
        setLoading(true);
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        LoginServices.getCards(config)
            .then((response: any) => {
                console.log(response);

                setLoading(false);
                if (response.data.Status == 1) {
                    setAllCards(response?.data?.Cards);
                    setCards(response?.data?.Cards);
                } else {
                    alert(response?.data?.Message);
                }

                console.log(response.data);
            })
            .catch((e: Error) => {
                setLoading(false);
                console.log(e);
            });
    };
    useEffect(() => {
        if (userId) {
            getCards();
        } else {
            alert("Login to see your dashboard");
            navigate("/");
        }
    }, []);
    const DeleteHandler = (e: any) => {
        e.stopPropagation();
        let CardId = e.currentTarget.getAttribute("custom-cardId");
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        if (window.confirm("Are you sure you want to delete this card?")) {
            setLoading(true);
            LoginServices.DeleteCard(config, CardId)
                .then((response: any) => {
                    setLoading(false);
                    console.log(response);
                    if (response.data.Status == 1) {
                        toast.success("Card Deleted", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        getCards();
                    } else {
                        toast.warning(response?.data?.Message, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setLoading(false);
                    }

                    // console.log(response.data);
                })
                .catch((e: Error) => {
                    setLoading(false);
                    console.log(e);
                });
        }
    };
    const onSubmit: SubmitHandler<FormData> = (data) => {
        if (PaymentGateWay == "") {
            alert("select Payment Gateway");
            return;
        }
        let userDetails: any = localStorage.getItem("userdetails");
        if (userDetails) {
            userDetails = JSON.parse(userDetails);
            setCommissionPercentage(userDetails?.BBPSChargesPercentage);
            setPayableAmount(
                (userDetails?.BBPSChargesPercentage / 100) *
                    Number(data.amount) +
                    Number(data.amount)
            );
        }
        let body = {
            ...data,
            ipAddress: userIpAddress,
            longitude: 0,
            latitude: 0,
            cardId: selectedata?.cardId,
        };
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        setModal(true);
    };
    console.log(PayableAmount);

    return (
        <div className="main-bg">
            {spin && <Spinner />}
            {modal && (
                <Confirm
                    submitenabler={spin}
                    commissionPercentage={commissionPercentage}
                    setSpinner={setSpin}
                    payHandler={() => {}}
                    passBlc={formData.amount}
                    apidatasend={{ cardId: selectedata?.cardId }}
                    commissionamount={PayableAmount}
                    show={modal}
                    PaymentGateWay={PaymentGateWay}
                    onHide={() => {
                        setModal(false);
                    }}
                />
            )}
            <Modal
                show={Edit}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="c-modal-pay-bills"
                onHide={() => {
                    if (!load) {
                        setEdit(false);
                    }
                }}>
                <ModalBody>
                    <div className="m-name">
                        <p className="m-name-payee">
                            {selectedata?.cardHolderName}
                        </p>
                        <p className="m-mobile-payee">
                            Caard Number:xxxx xxxx xxxx{" "}
                            {selectedata?.cardNumber}
                        </p>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <div className="pay-ip-div">
                                <input
                                    className="pay-ip-div-e1"
                                    type="number"
                                    {...register("amount", { required: true })}
                                    placeholder="Amount"
                                />

                                <p className={CardClasses["error"]}>
                                    {errors.amount?.message}
                                </p>
                            </div>
                            <div
                                className={"drop-down amount-data"}
                                style={{ gridColumn: "1/-1" }}>
                                <label htmlFor="">Payment Gateway</label>
                                <select
                                    name=""
                                    id=""
                                    value={PaymentGateWay}
                                    onChange={(e) => setGateway(e.target.value)}
                                    style={{ display: "block" }}>
                                    <option value="">
                                        Select Payment Gateway
                                    </option>
                                    <option
                                        value={2}
                                        disabled={
                                            AllGateways?.isCashFreeAvailable ==
                                            0
                                        }>
                                        Cashfree Utility{" "}
                                    </option>
                                    {localStorage.getItem("userid") !=
                                        "1111111" && (
                                        <option
                                            value={3}
                                            disabled={
                                                AllGateways?.isCashfreeEduAvailable ==
                                                0
                                            }>
                                            Cashfree Edu{" "}
                                        </option>
                                    )}

                                    <option
                                        value={1}
                                        disabled={
                                            AllGateways?.isRazorPayPGAvailable ==
                                            0
                                        }>
                                        Razor Pay{" "}
                                    </option>
                                </select>
                            </div>
                            <button
                                disabled={load}
                                style={{
                                    border: "none",
                                    backgroundColor: "inherit",
                                }}>
                                {!load ? (
                                    <img
                                        className="arrow-btn-payee"
                                        src="assets/img/arrow-right.png"
                                        alt=""
                                    />
                                ) : (
                                    <p>Please Wait</p>
                                )}
                            </button>
                        </form>
                    </div>
                </ModalBody>
            </Modal>
            {load && <Loader />}
            <div>
                <DashboardNav active={"cardPayments"}></DashboardNav>
            </div>
            <div>
                <header className={CardClasses["row_header"]}>
                    <h3>Credit Card Payments</h3>
                    <button
                        onClick={() => navigate("/dashboard/saved-cards/add")}>
                        Add Card
                    </button>
                </header>
                <input
                    className={CardClasses["input_search"]}
                    placeholder="Search By Phone Number"
                    style={{
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                    }}
                    onChange={(e) => {
                        setCards(
                            AllCards.filter((item: any) =>
                                item.mobileNumber.includes(e.target.value)
                            )
                        );
                    }}
                />
                <div className={CardClasses["Card_wrapper"]}>
                    {Cards.length > 0 ? (
                        Cards?.map((item: any) => (
                            <div
                                className={CardClasses["container"]}
                                onClick={() => {
                                    SetSelectedData(item);
                                    setEdit(true);
                                }}>
                                <div
                                    className={CardClasses["card"]}
                                    style={{ height: "200px" }}>
                                    <button
                                        className={CardClasses["Delete"]}
                                        onClick={DeleteHandler}
                                        custom-cardId={item.cardId}>
                                        Delete
                                    </button>
                                    <div className={CardClasses["card-inner"]}>
                                        <div className={CardClasses["front"]}>
                                            <img
                                                src="assets/map.png"
                                                className={
                                                    CardClasses["map-img"]
                                                }
                                            />
                                            <div className={CardClasses["row"]}>
                                                <img
                                                    src="assets/chip.png"
                                                    width="50px"
                                                />
                                                {item.network == "VISA" && (
                                                    <img
                                                        src="assets/visa.png"
                                                        width="50px"
                                                    />
                                                )}
                                                {item.network != "VISA" && (
                                                    <img
                                                        src="assets/mastercard.png"
                                                        width="60px"
                                                    />
                                                )}
                                            </div>

                                            <div>
                                                <div
                                                    className={`${CardClasses["row"]} ${CardClasses["card-no"]}`}>
                                                    <p>
                                                        {String(
                                                            item?.firstCardNumber
                                                        ).slice(0, 4)}
                                                    </p>
                                                    <p>
                                                        {" "}
                                                        {String(
                                                            item?.firstCardNumber
                                                        ).slice(4, 6)}
                                                        XX
                                                    </p>
                                                    <p>XXXX</p>
                                                    <p>{item?.cardNumber}</p>
                                                </div>
                                                <span>{item.issuerBank}</span>
                                            </div>
                                            <div
                                                className={`${CardClasses["row"]} ${CardClasses["card-holder"]}`}>
                                                <p>CARD HOLDER</p>
                                                <p>MOBILE NUMBER</p>
                                            </div>
                                            <div
                                                className={`${CardClasses["name"]} ${CardClasses["row"]}`}>
                                                <p>{item?.cardHolderName}</p>
                                                <p>{item?.mobileNumber}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="pay-bills-center">
                            <div>
                                <div>
                                    <img
                                        className="pay-bills-main-img"
                                        src="assets/img/pay-bills-main.png"
                                        alt=""
                                    />
                                    <p className="add-more-p">
                                        No Card Found Add your Card
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CardPayments;
