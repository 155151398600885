import React, { CSSProperties } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import LoadClasses from "./loader.module.css";
import { css } from "@emotion/react";

function Loader() {
    const override = css`
     display: block;
     margin: 0 auto;
     border-color: "#010080";
 `;
    return (
        <div
            className={LoadClasses["Loader-wrapper"]}
            style={{ zIndex: "10000" }}>
            <div className={LoadClasses["loader"]}>
                <FadeLoader
                    color="white"
                    loading={true}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    css={override}
                />
            </div>
        </div>
    );
}

export default Loader;
